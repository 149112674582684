<template>
  <div>
    <!--    <div class="timer">timer: {{ timer }}</div>-->
    <div v-if="show" class="spinner" :class="position"></div>
  </div>
</template>
<style lang="scss" scoped>
.timer {
  position: fixed;
  right: 30px;
  top: 30px;
  background: red;
  z-index: 20000;
}

.spinner {
  position: relative;
  z-index: 100000;
  pointer-events: none;

  &.top,
  &.bottom {
    position: fixed;
    right: 30px;
    bottom: 30px;
  }
}
</style>
<script>
import { useNavStore } from "@/stores";
import { computed, ref, toRefs, watch } from "vue";

export default {
  setup(props) {
    let timerInterval;
    let show = ref(false);
    let timer = ref(0);

    const { isApp } = toRefs(props);

    const spinnerState = computed(() => {
      return isApp.value ? useNavStore().getAppSpinner : true;
    });
    const position = computed(() => {
      return isApp.value ? (useNavStore().getTopBarState ? "top" : "bottom") : false;
    });

    const stopTimer = () => {
      clearInterval(timerInterval);
      show.value = false;
      timer.value = 0;
    };

    const startTimer = () => {
      timerInterval = setInterval(() => {
        timer.value = timer.value + 100;
        if (timer.value > 100) {
          show.value = true;
        }
      }, 20);
    };

    watch(
      spinnerState,
      (state) => {
        state ? startTimer() : stopTimer();
      },
      { immediate: true }
    );

    return {
      show,
      position,
      timer,
      spinnerState,
    };
  },
  props: {
    isApp: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
